import { withTranslation } from '@i18n';

const Error = (props) => {
    const {
        statusCode, Content, t,
    } = props;

    const statusCodes = {
        400: t('common:Bad_Request'),
        404: t('common:This_page_could_not_be_found'),
        405: t('common:Method_Not_Allowed'),
        500: t('common:Internal_Server_Error'),
    };

    // eslint-disable-next-line react/destructuring-assignment
    const title = props.title || statusCodes[statusCode] || 'An unexpected error has occurred';

    return (
        <Content statusCode={statusCode} title={title} />
    );
};

export default withTranslation()(Error);
