const crypto = require('crypto');

const encrypt = (text) => {
    const cipher = crypto.createCipheriv(process.env.ENCRYPTION_ALGORITHM,
        process.env.ENCRYPTION_KEY, process.env.ENCRYPTION_KEY?.substring(0, 16));
    let crypted = cipher.update(text, 'utf8', 'base64');
    crypted += cipher.final('base64');
    return crypted;
};

const decrypt = (text) => {
    const decipher = crypto.createDecipheriv(process.env.ENCRYPTION_ALGORITHM,
        process.env.ENCRYPTION_KEY, process.env.ENCRYPTION_KEY?.substring(0, 16));
    let dec = decipher.update(text, 'base64', 'utf8');
    dec += decipher.final('utf8');
    return dec;
};

module.exports = {
    encrypt,
    decrypt,
};
