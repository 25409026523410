/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

export const storeConfig = `
{
    storeConfig {
      product_image_min_height
      product_image_min_width
      msp_securitysuite_recaptcha_enabled
      msp_securitysuite_recaptcha_enabled_login
      msp_securitysuite_recaptcha_public_key
      msp_securitysuite_recaptcha_private_key
    }
  }
`;

export const availableStores = gql(`
query AvailableStores {
    availableStores {
        store_code
        store_name
        locale
        is_default_store
    }
}`);
